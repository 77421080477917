$layout-breakpoint-small: 480px;
$layout-breakpoint-medium: 960px;
$layout-breakpoint-large: 1440px;

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  font-size: 1em;
  list-style: none;
  box-sizing: border-box;
  width: 100%;
  user-select: none;
}

@font-face {
  font-family: Mondwest;
  src: url('./fonts/Mondwest-Regular.woff2') format('woff2');
}

header {
  position: fixed;
}
body {
  overflow-x: hidden;
  padding: 0 0.4em;
  font-family: sans-serif;
  font-size: 16px;
  -webkit-text-size-adjust: none;
  width: 100%;
}

section:first-of-type {
  padding-top: 2rem;
}
section {
  width: 100%;
}

a:link,
a:visited,
a:hover,
a:active {
  color: black;
}

h1 {
  font-size: 1rem;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 0.4em;
}

h2 {
  font-size: 2rem;
  line-height: 1em;
  display: block;
  font-weight: 500;
}
h2.title {
  padding-top: 0.5rem;
}

h3 {
  font-family: Mondwest, serif;
  font-size: 2.2rem;
  display: block;
  font-weight: 500;
}

p {
  padding-bottom: 2rem;
}

.photoproject {
  width: 100%;
}

.photoproject li {
  height: 180px;
  display: inline-block;
  padding: 0em 0.4em 0.4em 0em;
}

li img {
  height: 100%;
  width: auto;
}

.slideshow {
  display: grid;
  grid-template-columns: 1fr;
}

.left {
  justify-items: flex-start;
}
.right {
  justify-items: flex-end;
}

.slides {
  display: none;
  grid-column: span 1;
  grid-row: 1;
  width: 100%;
  max-width: 720px;
  background-color: black;
}

.txt-wrapper {
  max-width: 720px;
  padding-bottom: 2.4rem;
}

.slides img {
  width: 100%;
}

.showing {
  display: block;
  animation: fadeIn 0.3s;
}

.imprint p {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0.5em;
}

footer {
  padding-bottom: 1.5rem;
}

h3.info {
  padding-bottom: 2.4rem;
}

@media (min-width: $layout-breakpoint-large) {
  .slides,
  .txt-wrapper {
    max-width: 960px;
  }
}

@media (max-width: $layout-breakpoint-small) {
  .photoproject {
    width: 100%;
  }

  .photoproject li img {
    max-width: 100%;
  }

  .slides img {
    max-width: 100%;
  }

  h2 {
    font-size: 1em;
  }
  h3 {
    font-size: 1.1em;
  }
}

[style*='--ratio'] {
  height: 0;
  padding-bottom: calc(100% / (var(--ratio)));
  position: relative;
  box-sizing: border-box;
  background-color: black;

  > * {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
